
.titre-reveal{
    font-style: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white !important;
    background-color: green !important;
    height: 120px;
    margin-top:30px;
    border-radius: 10px !important;
    font-size: 24px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
   // align-items: center;
    & > h3{
        font-size: 26px;
        margin-left: 30px;
        //border:1px solid white;
        margin-bottom: 0px;
        // margin-top: 5%;
        // padding-top: 1%;
    }     
    & > p{
        margin-left: 30px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 18px;
        line-height: 18px;
        margin-top: 15px !important;
    }
}
.flippy-container{
    height: 90% !important;
}
.content-reveal{
    //overflow: scroll !important;
    width: 100%;
    margin-top: 2% !important;
    height: 65vh !important;
    background-color: white !important;
    //border-radius:5px;
    filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.2));
    display: flex;
    border:none !important;
    justify-content: center !important;
    align-items: center!important;
    //border:1px solid red ;
    flex-wrap: wrap;
  
    & .reveal-incipit{
        width: 90%;
        font-size: 1.5rem;
        font-family: Verdana, Arial, Tahoma, sans-serif;
        color: #336756;
        margin-bottom: 0px;
    }
    & .reveal-viewer {
        display: flex !important;
        width: 95%;
        height: 80%;
        //justify-content: space-between !important;
        //flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        //border:1px solid red ;
        margin-top: 0px !important;
        & .card-viewport{
         
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            //border:2px solid blue;
            height: 100% !important;
            width: 100% !important;
            .flippy-card-front{
                border-radius:10px;
                border: 1px solid lightgrey;
                height: 100% !important;
                width: 400px;
                max-width: 400px !important;
                margin:10px;
                cursor: pointer;
                .flippy-card-img-min{
                    height: 100%;
                    margin: auto !important;
                    //border: 1px solid green;
                    overflow: hidden;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                .flippy-card-text{
                    font-size: 20px;
                    font-family: Verdana, Geneva, Tahoma, sans-serif;
                    
                }  
            }
            .flippy-card-back{
                border-radius:10px;
                height: 100%;
                width: 400px;
                margin: auto !important;
                background-color:green;
                margin:10px;
                cursor: pointer;
                & .flippy-card-back-text{
                    background-color:green;
                    color:white;
                    text-align: left;
                    display: flex;
                    font-size:16px;
                }
                & h3{
                    font-size: 24px;
                    font-family: Verdana, Geneva, Tahoma, sans-serif;
                }
                & ul{
                    margin-top:2px;
                    margin-bottom: 2px;
                }
                & ul >li{
                    margin-left:5px;
                    margin-bottom: 10px;
                    & ul{
                        border :1px solid white;
                    }
                }
                & p{
                    margin-top:5px;
                }
            }
        }
           
        & .reveal-card-img-max{
            height: 200px;
            width: 250px;
            margin: auto;
            overflow: visible;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border: 1px solid lightgrey;
            border-radius:10px;
            cursor: pointer;
        }
        & .reveal-card-text{
            height: 40% !important;
            border-radius:10px;
            border: 2px solid #336756;
            margin:5px !important;
            align-items: center;
            width: 25% !important;
            display: flex;
            transition: color 0.5s, background-color 0.5s, scale 0.5s;
            filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.2));
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            & .text-reveal{
                font-size: 16px !important;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                margin: 0 auto;
                width: 80%;
                text-align: center;
            }
        }
        & .reveal-card-text:hover{
            background-color: #336756;
            color: white;
            //transform: scale(1.1);
        }
         
        
    }
}
.motion-end{
    width: 100%;
    //border:1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reveal-bloc-btn{
    //align-self: start;
    display: flex;
    justify-content: center;
    //border:1px solid green;
    width: 90%;
    height: 100px;
   & .reveal-conclusion{
       width: 75%;
       height: 80px;
       padding: 10px;
       font-size: 16px;
       //font-weight: bold;
       text-align: left;
       display: flex;
       justify-content: center;
       align-items: center;
       & p{
           margin-top:3px;
           margin-bottom: 3px;
       }
   }
    & .button-steps{
        width: 180px;
            height: 30px;
            //padding: 3%;
            //margin-left: 6%;
            margin-bottom: 1rem;
            margin-top: 1rem;
            text-align: center;
            background-color: #bd582d !important;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            border-radius: 20px;
            border:1px solid #bd582d;
            color: white;
            font-weight: 700;
            text-transform: none !important;
            transform: background-color 1s, color 0.5s;
       
    }
    & .button-stepsnpm:hover{
        background-color:white !important;
        color: #bd582d;
    & .arrow-btn-conclusion{
        color: #bd582d;
        animation: slide1 1s ease-in-out infinite;
    }
}
}
ul{
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    padding-inline-start: 0px !important;
}

.reveal-modal{
    //position: absolute;
    //left:25vw;
    //top:20vh;
    width:40vw;
    height: 60vh;
    color: white;
    //border: 2px solid #000;
    //box-shadow: 5px 5px 5px $lightgray;
    padding: 25px 40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    //top: 0%;
    //left: 20%;
    margin-left: auto;
    margin-right: auto;
    outline: none;
    border-radius:10px;
    background-color:#336756;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //transform: translate(-50%, -50%);
    & div>ul >li{
        //border:1px solid white;
        margin-bottom: 10px;
        & >ul{
            //border: 1px solid white;
        }
    }
  }
 @media only screen and (max-width:1000px){
 
}
  @media only screen and (max-width: 1500px) and (min-device-width: 480px) {
    .titre-reveal{
        height: 6rem;
        line-height: 30px;
        margin-top:10px;
        & > h3{
            margin-top: 0;
            padding-top: 1rem;
        }     
        & > p{
            margin-top: 0;
            line-height: 0;
        }
    }
    .content-steps{
        height: 80vh;
    }
    .reveal-modal{
        //top: 60%;
      }
}
@media only screen and (max-width:1200px){
    .reveal-modal{
        //position: absolute;
        width: calc((100vw/2) );
        height: auto;
        color: white;
        //border: 2px solid #000;
        //box-shadow: 5px 5px 5px $lightgray;
        padding: 25px 40px;
        //top: 80%;
        //left: 40%;
        margin-left: auto;
        margin-right: auto;
        outline: none;
        border-radius:10px;
        background-color:#336756;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //transform: translate(-50%, -50%);
        & div>ul >li{
            //border:1px solid white;
            margin-bottom: 10px;
        }
      }
}
@media only screen and (min-height: 401px) and (max-height: 800px) {
    .titre-reveal{
        height: 80px;
        & > h3{
            font-size: 1.4rem;
        }     
        & > p{
            margin-left: 30px;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-size: 1rem;
            margin-top: 5px !important;
        }
    }
    .content-reveal{
        height: 65vh !important;
        & .reveal-incipit{
            font-size: 1.2rem;
        }
        & .reveal-viewer {
            //border:1px solid red;
            height:80%;
           & .reveal-card-img-max{
                height: 120px;
                width: 200px;
                margin: auto;
                overflow: visible;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                border: 1px solid lightgray;
                
            }

        }
        .reveal-bloc-btn{
            height:60px;
            & .reveal-conclusion{
                height: 60px;
                font-size: 0.8rem;
                line-height: 0.9rem !important;
                padding: 5px;
            }
        }
    }
}
@media only screen and (max-height: 600px) {
    .titre-reveal{
        height: 60px;
        & > h3{
            font-size: 1.2rem;
            margin-top: 0 !important;
            //border:1px solid white;
            line-height: 1.2rem;
        }     
        & > p{
            margin-left: 30px;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-size: 0.9rem;
            margin-top: 10px !important;
        }
    }
    .content-reveal{
        height: 72vh !important;
    }
    .reveal-bloc-btn{
        height:60px;
        & .reveal-conclusion{
            height: 60px;
            font-size: 0.8rem;
            line-height: 0.9rem !important;
            padding: 5px;
        }
    }
    .flippy-card-back-text{
        font-size:0.8rem !important;
        & .h3{
            font-size: 1.2rem !important;
        }
    }
    .reveal-card-img-max{
        height: 100px !important;
        width: 200px !important;
        margin: 15px !important;
        overflow: visible;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border: 1px solid lightgray;
        border-radius:10px;
        cursor: pointer;
    }
    .reveal-card-text{  
        & .text-reveal{
            & h3{
                font-size: 1rem !important;
            }
        }
    }
    .reveal-modal{
        //position: absolute;
        //left:15vw;
        //top:10vw;
        width:60vw;
        height: auto;
        color: white;
        //border: 2px solid #000;
        //box-shadow: 5px 5px 5px $lightgray;
        padding: 25px 25px;
        //top: 0%;
        //left: 20%;
        border-radius:10px;
        background-color:green;
        font-size: 1rem;
    }
}
@media only screen and (max-width: 999px) {
    .flippy-card-front{
        //border: 1px solid red !important;
        width:300px !important;
    }
    .flippy-card-back{
        width:300px !important;
    }

}
@media only screen and (max-width:1375px) and (max-height:780px){
    .content-reveal{
        height: 62vh !important;
        & .reveal-incipit{
            font-size: 1.1rem;
        }
    }
    .reveal-bloc-btn{
        //border:1px solid red !important;
        height: 30px !important;
        margin-top: -5px !important;
    }
}