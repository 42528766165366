@import '../_master.scss';
.Carousel-indicators-2{
   // border:1px solid red !important;
    //margin-top:-25px!important;
    width:100% !important;
    color: white !important;
    //display: none !important;
}
.medaille{
  height: 20vh !important;
  width: auto !important;
 // border:1px solid;
}
.viewer{
   /*  display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: left !important;
    color: $darkgray !important; */
    width:100vw!important;
    height: 100vh;
   // border:1px solid green;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    margin: 0;
    background-color: #fcfcff;
    background-image: url("../images/forme.svg");
    background-size:100%;
    background-repeat: no-repeat;
    //background-position: 0 65vh;
    background-position: center bottom;

    & .full{
        width: 100vw !important;
        height: 100vh;
        margin:0 !important;
        padding: 0 !important;
    }
    & .module-viewer{
        //border:1px solid red;
        //margin-left: 10% !important;
        margin:0px !important;
        display: flex;
        justify-content: center;
        & .module-titre{
            color: $darkgray !important;
            width: 100%;
            height:100px;   
           
            background-color:white ;
            filter: drop-shadow(0px 2px 10px rgba(43, 43, 43, 0.08));
            
            //background-position:-26px;
            display: flex;
            align-items: center;
            justify-content: center;
            & .h-wrapper{
             // border:1px solid red;
              display: flex;
              align-items: center;
              & .bloc-marque{
                margin-left:0;
                width: 140px;
                height:50px;
                background-image: url('../images/logo.png');
                background-size: contain;
                background-position: center;
                margin-top: 0px;
                background-repeat: no-repeat;
                //border:1px solid red;
                 
              }
              & .titre-content{
                  border-left: 1px solid #e9edf1;
                  height: 100px;
                  margin-left: 40px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  //border:1px solid red;
                  width: 60%;
                  & h1{
                      text-align: left;
                      //margin-top:-11px;
                     //font-family: Verdana, Geneva, Tahoma, sans-serif;
                      padding: 0px;
                      margin-left: 40px;
                      margin-bottom: 0px;
                      font-weight: 800;
                      font-size: 1.8rem;
                      color:  #00b5d3;
                      }
                  & p{
                      margin-top:2px;
                      //font-family: Verdana, Geneva, Tahoma, sans-serif;
                      margin-left: 40px;
                      font-size: 1.4rem;
                      font-weight: 400;
                      text-align: left;
                      color:  #3f3f3e;
                  }
              }
            }
            
        }
        & .shadow-c{
          //border:1px solid red;
          border-radius: 8px;
          filter: drop-shadow(0px 0px 20px rgba(43, 43, 43, 0.15)) !important;
        }
        & .module-intro{
            background-color: white;
            
            color: white;
            padding: 0 !important;
            margin:0 !important;
            display: flex;
            justify-content: flex-start;
      
            align-items: center;
            border: none !important;
            height: 45vh;
            max-height: 300px;
          
            & .content-masthead{
                //border:1px solid white;
                max-width: 850px;
                width: auto;
                margin-left: 4%;
                //border:1px solid red;
                margin-top: 10px;
                max-height:350px;
                overflow: hidden;
                text-align: left;
                & h3{
                    font-size: 1.6rem;
                    font-weight: 800;
                   // font-family: Verdana, Geneva, Tahoma, sans-serif;
                    margin: 0px;
                    color: #3f3f3e;
                }
                & p{
                    font-size: 1rem;
                    color: #3f3f3e;

                    & b{
                        font-weight: 800;
                        border-bottom: 2px solid #00b5d3;
                    }
                }

            }
            & .bloc-intro{
             /*    padding-bottom: 0;
                padding-top: 6px;
                & p{
                    margin: 5px
                }
                & h3{
                    margin: 8px;
                } */
            }
            & .mid-intro{
                padding-bottom: 0;
            }
            & .img-intro{
                width: 40%;
                //max-width: 300px;
                height: auto;
            }
        }
        & .section-divider{
            margin: 0;
            padding-top: 0 !important;
            //border:1px solid red;
            display:flex;
            background-image: url('../images/shim-1.png');
            background-position: 0px -46px;
            background-repeat: no-repeat;
            background-size: 46px 92px;
             & div{
                 width: 573px;
                 height:96px;
                 background-color: #29aae1;
                 margin-left: 148px;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 & h1{
                    font-size: 28px;
                    margin: 0px;
                    text-transform: uppercase;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    text-align: center; 
                    color: #34398D;
                 }
             }
             & .divider-background{
                 width: 40vw;
                 margin: 0px;
                 background-color: white!important;
                 background-image: url('../images/shim-2.png');
                 background-position: 30vw -25px;
                 background-size: 119px 85px;
                 background-repeat: no-repeat;
                // border: 1px solid red;
             }
        }
        & .grid-module{
            //border: 1px solid red;
            width: 100%;
            //margin-left:148px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
            margin-top: 30px;;
            //overflow: auto;
        }
        & .socle-card{
            //height: 18rem;
            border-radius: 8px;
            width:33%;
            min-width: 280px;
            max-width: 380px;
            height: 350px;
            filter:drop-shadow(0px 0px 20px rgba(0, 0, 0,0.15));
            border: none;
            //flex-grow: 1;
            display: flex;
            margin-bottom: 20px;;
            flex-direction: column;
            //justify-content: space-between;
            position: relative;
            & .socle-card-wrapper{
                display: flex;
                padding: 4% 6% 4%;
                justify-content: space-between;
                & .socle-card-content-wrapper{
                    width:100%;
                    & .titre-socle-wrapper{
                      display: flex;
                      align-items: center;
                      & h4.titre0{
                        color:#3f3f3e;
                        font-size: 1.8rem;
                        //font-family: Verdana, Geneva, Tahoma, sans-serif;
                        font-weight: bold;
                        //letter-spacing: 2px;
                          & b{
                            border-bottom: 1px solid #a097e4;;
                          }
                      }
                      & h4.titre2{
                        color:#3f3f3e;
                          font-size: 1.8rem;
                          //font-family: Verdana, Geneva, Tahoma, sans-serif;
                          font-weight: bold;
                        & b{
                          border-bottom: 1px solid #85d1a3;;
                        }
                      }
                      & h4.titre1{
                        color:#3f3f3e;
                          font-size: 1.8rem;
                          //font-family: Verdana, Geneva, Tahoma, sans-serif;
                          font-weight: bold;
                        & b{
                          border-bottom: 1px solid #f1b56e;;
                        }
                      }
                      & .icon-lock{
                        //border: 1px solid red;
                        width: 20px;
                        height: 20px;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-right: 5px;
                      }
                    }
                 
                }
            }
            & .button-socle{
                width: 120px;
                height: 38px;
                //padding: 3%;
                margin-left: 6%;
                margin-bottom: 1rem;
                margin-top: 1rem;
                position:absolute;
                bottom:10px;
                text-align: center;
               // background-color: #cb531b !important;
                border-radius: 25px;
                //border:1px solid #ec6e51;
                color: white;
                font-weight: 700;
                text-transform: none !important;
                //transform: background-color 1s, color 0.5s;
                cursor: pointer;
                & .btn-arrow{
                    font-size : 2em;
                    color: #273a50;
                    margin-left:0 .2rem;
                }
                
            }
            & .button-socle0:hover{
                background-color:white !important;
                color: #a097e4;
                border:1px solid #a097e4;
            }
            & .button-socle1:hover{
              background-color:white !important;
              color: #f1b56e;
              border:1px solid #f1b56e;
            }
            & .button-socle2:hover{
              background-color:white !important;
              color: #85d1a3;
              border:1px solid #85d1a3;
          }
            & .progress-bloc{
                padding: 4% 6% 4%;
                padding-top: 0;
                padding-bottom: 0;
                width: 85%;
                position: absolute;
                bottom:80px;
                & .moduleProgress{
                    background-color: &white !important;
                    border-radius: 10px;
                    border:1px solid #e9edf1;
                    height: 10px;
                    color: #e9edf1 !important;
                }
                & .progress-text{
                    text-align: right;
                }
            }
        }
        & .timerIcon{
            display: flex;
            align-items: center;
            //border: 1px solid red;
             margin-top: 20px;
             margin-bottom: 20px;
            & span{
                margin-top: 2px;
                font-size: 1rem;;
            }
        }
        & .card-titre-socle{
            //font-family: $font-title;
            font-weight: 300;
            //color: black;
            text-align: left;
            font-stretch: normal;
            font-style: normal;
            //border:1px solid white;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        & .p-socle{
            font-size: 1.1rem;
            text-align: left;
            color: #3f3f3e;
           //font-family: Verdana, Geneva, Tahoma, sans-serif;
        }
        & .card-progress{
            font-family: $font-title;
            line-height: 0.89;
            font-weight: 900;
            color: white;
            text-align: left;
            font-size: 45px;
            font-stretch: normal;
            font-style: normal;
            //border:1px solid white;
            margin-top: 5px;
            margin-bottom: 20px;
        }
        & .iconComplete{
            font-size : 2em;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.3rem;
        }
    }
    
}

@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
  }



@media (min-width: 1700px) {
    .viewer{
        & .module-viewer{
            //margin-left: 10% !important;
            //max-width: 1700px !important;
        }
        & .module-viewer{
            & .module-intro{
                & .img-intro{
                    height: auto;
                }
                & .bloc-intro{
                    padding-bottom: 8px;
                    padding-top: 6px;
                    & p{
                        margin: 16px
                    }
                    & h3{
                        margin: 20px;
                    }
                }
                & .mid-intro{
                    padding-bottom: 8px;
                    padding-top: 6px;
                    & p{
                        margin: 16px
                    }
                    & h3{
                        margin: 20px;
                    }
                }
            }
        }
    }
    
}
@media (min-width: 1800px) {
    .viewer{
        & .module-viewer{
            //margin-left: 10% !important;
            //max-width: 1800px !important;
        }
    }
}
@media (min-width: 1900px) {
    .viewer{
        & .module-viewer{
            //margin-left: 10% !important;
           // max-width: 1900px !important;
        }
    }
}
@media  (max-height: 800px){
.viewer{
    & .module-viewer{
       
        & .module-intro{
                //background-color: red ;
                height: 41vh!important;
                
                & .content-masthead{
                    //border:1px solid white;
                
                    & h3{
                        font-size: 1.2rem !important;
                        //font-family: Verdana, Geneva, Tahoma, sans-serif;
                        margin: 0px;
                    }
                    & p{
                        font-size: 0.9rem !important;

                        & b{
                            font-weight: normal;
                            //border-bottom: 2px solid #cb531b;
                        }
                    }

                }
                & .bloc-intro{
                /*    padding-bottom: 0;
                    padding-top: 6px;
                    & p{
                        margin: 5px
                    }
                    & h3{
                        margin: 8px;
                    } */
                }
                & .mid-intro{
                    padding-bottom: 0;
                }
                & .img-intro{
                    width: 40%!important;
                }
            }
        }
}
.titre-content{
    margin-left: 30px !important;
    display: flex;
   //@at-root border:1px solid red;

    & h1{
        font-size: 1.6rem !important;
        color:  #cb531b;
        }
    & p{
        font-size: 1rem !important;
        font-weight: bold;
        text-align: left;
        color:  #cb531b;
    }
}
}
@media only screen and (min-width:1031px) and (max-width:1370px){
    .viewer{
      //border:1px solid red !important;
        & .socle-card{
            
            //width: 100% !important;
            width: 30% !important;
            max-width: 100% !important;
        }

    }
    .img-intro{
        width: 50% !important;
        height: auto;
       // border:1px solid white;
    }
    .titre-content{
        margin-left: 30px !important;
        display: flex;

        & h1{
            font-size: 1.6rem !important;
            color:  #cb531b;
            }
        & p{
            font-size: 1rem !important;
            font-weight: bold;
            text-align: left;
            color:  #cb531b;
        }
    }
   
}
@media only screen and (min-width:651px) and (max-width:1050px){
  html { font-size: 12px !important;  height: -webkit-fill-available;}
  body {
    min-height: 100%;
    min-height: -webkit-fill-available;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
  }
  * {
    -webkit-backface-visibility: hidden;
  }
  .viewer {
    padding:0px !important;
    height: 100% !important;
   // border:1px solid red;
    -webkit-transform: translate3d(0,0,0) !important;
    & .module-viewer{
      //border:1px solid red;
      & .socle-card{
        width: 100%;
        max-width: 100%;
        //border: 1px solid red,;
        height: 270px;
        & .progress-bloc{
          //border:1px solid red;
          bottom: 60px !important;
          width: 90%;
      
        }
      }
      & .module-intro{
        justify-content: center;
        align-items: center;
        & .content-masthead{
          margin-top: 0px;
          width: 90%;
          //border:1px solid red;
          & h2{
            //border: 1px solid red;
            font-size: 1.8rem;
            margin: 0px 0px 5px 0px;
          }
          
          & p{
            font-size: 1.2rem;
            margin :0px 0px 5px;
            & i{
              font-size: 1.2rem;
            }

          }
          & ul{
            padding: 0px;
            & li {
              font-size: 1rem;
              margin-left: 15px !important;
              //border :1px solid red;
            }
          }
      }
      }
    }
  }
  .viewer{
    height: 100% !important;
    -webkit-transform: translate3d(0,0,0) !important;
    & .activity-viewer{
      & .steps-bloc-titre{
        height: 80px;
        & .steps-titre{
          margin-left: 4.5%;
          width: 40%;
          height: 80px;
        }
        & .steps-titre::after{
          height: 105px;
          background-position: 0 -25px;
        }
        & .steps-sous-titre{
          margin-right: 4.5%;
          width: 60%;
          
        }
      
      }
      & .module-texte{
        height: 80px;
        //display: none;
        // border:1px solid red;
        margin-left: 4.5%;
      }
      
    }
    & .top-page-activity{
      width:92% !important;
      height: 100%;
      -webkit-transform: translate3d(0,0,0) !important;
      & .steps-bloc-titre{
        height: 80px;
        & .steps-titre{
          //margin-left: 4.5%;
          width: 40%;
          height: 80px;
        }
        & .steps-titre::after{
          height: 105px;
          background-position: 0 -25px;
        }
        & .steps-sous-titre{
          //margin-right: 4.5%;
          width: 60%;
          & >p{
            font-size: 1.2rem;
          }
          
        }
      
      }
      & .arrow-container{
        & .wrapper-intro{
          margin-top: 30px;
          width: 100% !important;
          & .content-intro{
            height: 50vh!important;
            & .intro-img{
              height: 50vh;
            }
            & .content-intro-incipit{
              height: 90%;
              overflow-y: auto;
              & .text{
                font-size: 1.1rem;
              }
            }
          }
          & .content-conclusion{
            flex-wrap: nowrap;
            height: 50vh !important;
            & .content-conclusion-incipit{
              font-size: 1.1rem;
            }
          }
        }
        & .reveal-wrapper{
          margin-top: 30px;
          width: 100% !important;
          & .content-reveal{
            height: 50vh !important;
          }
        }
        & .decodage-wrapper{
          margin-top: 30px !important;
          width: 100% !important;
          & .decodage-content{
            height: 45vh !important;
            padding-bottom: 30px;
            & .decodage-content-text::after{
              width: 10%;
              bottom: -3px;
              left: 0px;
            }

          }
        }
        & .wrapper-info{
          margin-top: 30px;
          width: 100% !important;
          & .content-bloc{
            height:50vh !important;
          }
        }
        & .wrapper-quizz{
          margin-top: 20px;
          width: 100% !important;
          
        }
      }
    }
  }
}
//Mobile 
@media only screen and (max-width:650px){
    html { font-size: 12px !important;  height: -webkit-fill-available;}
    body {
      min-height: 100%;
      min-height: -webkit-fill-available;
      -webkit-overflow-scrolling: touch;
     -webkit-transform: translate3d(0,0,0);
     -webkit-backface-visibility: hidden;
    }
  
    button {
      text-transform: uppercase !important;
      font-size: 1.2rem !important;
    }
    .viewer {
      padding:0px !important;
      height: 100% !important;
      -webkit-transform: translate3d(0,0,0) !important;
      & .module-viewer{
        //border:1px solid red;
        margin-right: 2.5%;
        margin-left: 2.5%;
        padding: 2.5% 2.5%;
       padding: 0px;
        margin:0px !important;
        -webkit-transform: translate3d(0,0,0) !important;
        & .module-titre{
            //flex-wrap:wrap;
            background-position: 100% 0 !important;
          & .bloc-marque{
            margin-top: 10px;
            height: 80px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            width: 30%;
          }
          & .titre-content{
              border-left:none;
              width: 65%;
              margin-left: 10px !important;
              & .titre{
                  margin-left: 0px;
                  text-transform: uppercase;
                  font-size: 1.4rem !important;
              }
              & .sous-titre{
                  display: none;
              }
          }
        }
        & .module-intro{
          height: 30vh !important;
          position: relative;
          & .img-intro{
            display: inline-block;
            width: 100% !important;
            height: auto !important;
          }
          & .content-masthead{
            margin-top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(#00b5d3,0.7) ;
            //display: none;
            position: absolute;
            top:0px;
            left: 0;
            margin: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            & h2,h3{
              //border: 1px solid red;
              font-size: 1.6rem;
              margin: 0px 0px 5px 0px;
              width: 80%;
            }
           
            & p{
              width: 80%;
              font-size: 1rem;
              margin :0px 0px 5px;
              & i{
                font-size: 1rem;
              }
  
            }
            & ul{
             padding: 0px;
              & li {
                font-size: 1rem;
                margin-left: 15px !important;
                //border :1px solid red;
              }
            }
          }
        }
        
        & .module-texte{
          width:95%;
          margin-left: 2.5%;
        }
        & .grid-module{
            margin-top: 10px;
        }
        & .socle-card{
            width: 98% !important;
            max-width: 98%;
            margin-left: 1%;
          & .progress-bloc{
            //border:1px solid red;
            bottom: 60px !important;
            width: 90%;
        
          }
          & .button-socle{
            margin-left: 5%;
            width: 90%;
          }
        }   
      }
    }
    .viewer{
      height: 100% !important;
      -webkit-transform: translate3d(0,0,0) !important;
      overflow: hidden;
      & .nav{
          height: 70px;
          //border:1px solid red;
          //border-bottom: 2px solid #006b54 ;
          & .n-wrapper{
            & .titre-content{
              height: 70px;
            }
          }
          & .module-titre{
            height: 70px !important;
           // background-position: 360px -20px !important;
            width: 60%;
            //margin-left: 20px !important;
            & .bloc-marque{
                margin-left: 20px !important;
            }
          }
      }
      & .s-viewer{
       // border:1px solid red;
        padding:0px;
      }
      & .activity-viewer{
        -webkit-transform: translate3d(0,0,0);
         // margin:0px !important;
        & .steps-bloc-titre{
          height: 80px;
          margin-top: 90px !important;
          & .steps-titre{
            margin-left: 2.5%;
            width: 96%;
            height: 80px;
            & >h1{
                width: 90%;
            }
          }
          & .steps-titre::after{
            height: 105px;
            background-position: 0 -25px;
          }
          & .steps-sous-titre{
            margin-right: 0%;
            display: none;
            width: 10%;
            & >p{
              display: none;
            }
          }
       
        }
        & .module-texte{
          height: 82px;
          display: none;
        }
        & .activity-container{
          margin-top: 10px !important;
          width: 96% !important;
          margin-left: 2%;
         // margin-right: auto;
          padding-left: 0px;
          padding-right: 0px;
          & .activité-bloc-card{
            flex-wrap: wrap;
            height: auto;
            max-height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            & .checked-icon{
              width: 100px;
              height: 100px;
              top:5%;
              left:35%;
            }
            & .card-thumbnail{
              width: 100%;
              height: 150px;
            }
            & .activité-bloc-text{
              width: 95%;
              height: 180px;
              & > h4{
                margin-top: 5px;
                font-size: 1.4rem;
                margin-left: 0%;
                width: 100%;
                 & span{
                   font-size: 1.4rem;;
                 }
              }
              & >div{
                margin-left: 0%;
                width: 100%;
              }
              & p{
                margin-left: 0%; 
                width: 100%;
              }
              & .activité-bloc-text-description{
                display: none;
              }
              & .button-activity{
                position:absolute;
                margin-top: 20px;
                width: 90%;
                left: 5% !important;
                bottom: 15px !important;
              }
            }
          }
         
        }
      }
      & .top-page-activity{
        width:98% !important;
        height: 100% !important;
        margin-top: 80px !important;
        -webkit-transform: translate3d(0,0,0) !important;
        margin-left: 1% !important;
        margin-right: 1% !important;
        overflow: visible !important;
        & .save-btn{
          top:65px;
          right: 0px;
          width: 52px;
          height: 50px;
          & .save-icon{
            font-size: 2rem !important;
          }
        }
        & .info-bulle-2{
          display: none;
        }
        & .steps-bloc-titre{
          height: 80px;
          width: 100%;
          margin-top: 2px !important;
          & .steps-titre{
            margin-left: 1%;
            width: 85%;
            height: 80px;
           
          }
          & .steps-titre::after{
            height: 105px;
            background-position: 0 -30px;
          }
          & .steps-sous-titre{
            margin-right: 0%;
            width: 15%;
            position: relative;
            & >p{
              display: none;
            }
            & .close-btn{
              margin-right: 1px !important;
              position: absolute;
              align-self: flex-start;
              //border:1px solid red;
              top:10px;
              right: 10px;
              z-index: 400;
              //color:white !important;
            }
          }
       
        }
        & .content-intro{
            height: 100% !important;
            flex-wrap: wrap;
            //border:1px solid red;
            & .intro-img{
              width: 100%;
              height: 200px;
            }
            & .content-intro-incipit{
              width: 100%;
              & .titre-intro{
                width: 90%;
                & >h3{
                  margin-left: 0px;
                  font-size: 1.4rem;
                }
              }
              & .text{
                width: 100%;
                margin-left: 0px;
                margin-right: 0px;
                padding-right: 0px;
                font-family: MARIANNE-LIGHT, Geneva, Tahoma, sans-serif;
                font-size: 1.2rem;
                 & >p{
                   margin-left: 0px;
                   margin-right: 0px;
                   padding-right: 0px;
                 }
              }
              & .intro-bloc-btn{
                margin-left: 0px;
                & .button-intro{
                  width: 100%;
                  margin-left: 0%;
                }
              }
            }
         
          & .content-conclusion{
            flex-wrap: wrap;
            height: 100% !important;
            & .conclusion-img{
              width: 100%;
              height:200px;
            }
            & .content-conclusion-incipit{
              width: 100%;
              & .titre-conclusion{
                margin-left: 0px;
              }
              & .text-conclusion{
                margin-left: 0px;
              }
              & .conclusion-bloc-btn{
                margin-left: 0px;
                justify-content: center;
                align-items: center;
                & .button-conclusion{
                  width: 90%;
                }
              }
            }
          }
          }
        & .content-video{
            height: auto !important;
            & .video-container{
                height: auto !important;
                & .video-wrapper-2{
                    width: 90% !important;
                }
                & .video-bloc-btn{
                    position: initial;
                    font-family: MARIANNE-REGULAR, Geneva, Tahoma, sans-serif;
                    & .button-video{
                    width: 90% !important;
                    }
                }

            }
           
        }
        & .content-conclusion{
            flex-wrap: wrap;
            height: 100% !important;
            //overflow: hidden;
            & .conclusion-img{
              width: 100%;
              height:200px;
            }
            & .content-conclusion-incipit{
              width: 100%;
              & .titre-conclusion{
                margin-left: 0px;
              }
              & .text-conclusion{
                margin-left: 0px;
              }
              & .conclusion-bloc-btn{
                margin-left: 0px;
                justify-content: center;
                align-items: center;
                & .button-conclusion{
                  width: 90%;
                }
              }
            }
          }
        & .content-reveal{
        height: 100% !important;
        padding-bottom: 20px;;
            & .reveal-viewer{
                margin:0px !important;
                
                & .reveal-card-text{
                width: 80% !important;
                margin-top: 10px !important;
                margin-bottom: 10px !important;
                height: 50px !important;
                & .text-reveal{
                    font-size: 1.1rem !important;
                }
                }
            
            }
        }
        & .act-container{
            flex-wrap: wrap;
            height: 100%!important;
            & .wheel-interactive{
                margin-top: 10px;
                width: 100%;
            }
            & .video-player-container{
              width: 100%;
              & .video-bad{
                  width: 95%;
                 &>h2{
                   font-size: 1.6rem;
                 }
                 & >h2::after{
                   height: 5px;
                 }
              }
            }
            & .video-content-container{
              justify-content: flex-start ;
              margin-top: 10px;
              width: 100%;
              height: 100%;
              min-height: 300px;
             // overflow: visible;
              & .video-bad{
                height: 100%;
                width: 95%;
                & .video-wrapper-3{
                    width: 100% !important;
                    & .video-player{
                        width: 100% !important;
                    }
                    & .video-bloc-btn{
                        width: 95%;
                         & .button-intro{
                           width: 100% !important;
                           font-size: 1.1rem !important;
                           margin:0px !important;
                         }
                      }
                }
                &>h3{
                  font-size: 1.2rem;
                }
                & >h2::after{
                  height: 5px;
                }
             }
              & .content-bad{
                width: 98%;
                align-items: center;
                 & >h3{
                   font-size: 1.2rem;
                 }
                 & .video-bloc-btn{
                   width: 100%;
                    & .button-situation{
                      width: 100% !important;
                      font-size: 1.1rem !important;
                    }
                 }
              }
              & .content-good{
                width:98%;
              }
              & .quizz-viewer{
                & .question-bloc{
                  font-size: 1.2rem !important;
                }
                & .bloc-items{
                  & .quizz-item{
                    font-size: 1rem !important;
                  }
                }
                & .quizz-feedback{
                  font-size: 1rem !important;
                }
              }
            }
          }
        & .content-wrapper{
            height: 100% !important;
            width: 100% !important;
            flex-wrap: wrap;
            align-items: center;
            margin-right: 0px;
            & .quizz-feedback{
              //position: initial;
              z-index:2000;
              width: 100%;
              height: 180px;
              font-size: 0.9rem;
              top:-10px
            }
            & .intro-img{
              width: 100%;
              height: 100px;
            }
            & .content-quizz{
              width: 95%;
              margin:0px;
              justify-content: center;
              margin-left: 2.5%;
              & .quizz-viewer{
                padding: 0px;
                width: 95%;
                & .question-bloc{
                  line-height: 1.2rem;
                  margin-top: 5px;
                  width: 91% !important;
                }
                & .bloc-items{
                 width: 100%;
                 overflow: hidden!important; 
                 & .quizz-item{
                    width: 98%;
                    font-size: 1.1rem !important;
                 }
                }
              }
            }
            & .steps-bloc-btn{
             // border:1px solid red;
              & .button-quizz{
               // color:white;
              }
            }
          }
        & .content-bloc{
        flex-wrap: wrap;
        height: 100% !important;
        & .wrapper-i{
            width:100%;
            height: auto;
            border-width: 1px;
        }
        & .content-infographie{
            margin-top: 10px;
            margin-left: 0px;
            & .infographie-bloc-btn{
            flex-wrap: wrap;
            & .button-download{
                width: 90%;
            }
            & .button-intro{
                width:90%;
            }
            }
        }
        }
        & .arrow-container{
          & .arrow-back{
            display: none;
          }
        
            
          & .reveal-wrapper{
            margin-top: 30px;
            width: 100% !important;
           
          }
          & .decodage-wrapper{
            margin-top: 30px;
            width: 100% !important;
            & .decodage-content{
              height: 100% !important;
              padding-bottom: 30px;
              & .decodage-card{
                margin-top:20px !important;
                padding:10px;
                width:90%;
                font-size: 1.4rem;
                height: 150px;
              }
  
            }
          }
          & .wrapper-info{
            margin-top: 30px;
            width: 100% !important;
            & .content-bloc{
              flex-wrap: wrap;
              & .wrapper-i{
                width:100%;
                height: auto;
                border-width: 1px;
              }
              & .content-infographie{
                margin-top: 10px;
                margin-left: 0px;
                & .infographie-bloc-btn{
                  flex-wrap: wrap;
                  & .button-download{
                    width: 90%;
                  }
                  & .button-intro{
                    width:90%;
                  }
                }
              }
            }
          }
          & .wrapper-video{
            margin-top: 30px;
            width: 100% !important;
            & .video-container{
              & .video-wrapper-2{
                width: 100% !important;
              }
              & .video-bloc-btn{
                position: initial;
                font-family: MARIANNE-REGULAR, Geneva, Tahoma, sans-serif;
                & .button-video{
                  width: 90% !important;
                }
              }
            }
          }
          & .wrapper-quizz{
            margin-top: 30px;
            width: 100% !important;
           
            
          }
          & .act-wrapper{
            margin-top: 30px;
            width: 100% !important;
            & .act-container{
              flex-wrap: wrap;
              height: 100%!important;
              & .video-player-container{
                width: 100%;
                & .video-bad{
                   &>h2{
                     font-size: 1.6rem;
                   }
                   & >h2::after{
                     height: 5px;
                   }
                }
              }
              & .video-content-container{
                margin-top: 30px;
                width: 100%;
                & .content-bad{
                  width: 98%;
                  align-items: center;
                   & >h3{
                     font-size: 1.2rem;
                   }
                   & .video-bloc-btn{
                     width: 100%;
                      & .button-situation{
                        width: 100% !important;
                        font-size: 1.1rem !important;
                      }
                   }
                }
                & .content-good{
                  width:98%;
                }
                & .quizz-viewer{
                  & .question-bloc{
                    font-size: 1.2rem !important;
                  }
                  & .bloc-items{
                    & .quizz-item{
                      font-size: 1rem !important;
                    }
                  }
                  & .quizz-feedback{
                    font-size: 1rem !important;
                  }
                }
              }
            }
          }
        }
      }
    }
   
    .decodage-modal{
      width: 60vw !important;
     
    }
    .practice-modal{
      width: 80vw !important;
      padding: 10px 10px !important;
    }
    .over-notification{
      & .notification{
        width: 80vw !important;
      }
    }
    .reveal-modal{
        position:relative !important;
        width: 70vw !important;
    }
  }
  //tablette
