@import '../_master.scss';

.titre-video
{
    padding-bottom: 0;
    text-align: left !important;
    margin-top: -2rem !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > h3{
        margin: 0;
        font-style: bold;
        letter-spacing: -1.5px;
        font-size: 35px;
    }
    & p{
        margin-top: 40px;
        color: #34398D;
        //letter-spacing: 0.5px;
        padding-bottom: 0 !important;
        font-size: 16px;
        //border:1px solid red;
    }   
    
}
.act-container{
    width: 100%;
    margin-top: 2% !important;
    height: 65vh !important;
    background-color: white !important;
    border-radius:10px !important;
    filter:drop-shadow(0px 0px 20px rgba(0, 0, 0,0.15));
    display: flex;
    justify-content: center;
    & .wheel-interactive{
        width: 50%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & .video-content-container{
        width: 50%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff8f0;
        overflow-x: hidden;
        overflow-y: auto;
         & >h3{
             color: white;
             //font-family: Verdana, Arial, Tahoma, sans-serif;
             width: 80%;
             font-weight: 800;
             color: #3f3f3e;
             text-align: center;
             margin-top: auto;
             margin-bottom: auto;
         }
        & .quizz-viewer{
            border:1px solid white;
            width: 90%;
            //font-family: Verdana, Arial, Tahoma, sans-serif;

            & .question-bloc{
                font-weight: 700;
                font-size: 1.2rem;
                padding-bottom: 5px;
                color: #3f3f3e;
                border-bottom: 1px solid #3f3f3e;
            }
            & .bloc-items{
                & .quizz-item{
                    display: flex;
                    align-items: center;
                    font-size: 0.9rem;
                    color: 3f3f3e;
                    border:2px solid #3f3f3e;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
                & .quizz-item:hover{
                    background-color: #3f3f3e;
                   // border:none;
                    & > * {
                        color: white;
                    }
                }
            }
            & .steps-bloc-btn{
                display: flex;
                //justify-content: flex-end;
                align-items: center;
                //border:1px solid red;
                width:99%;
                & .button-quizz{
                    width: 220px;
                    height: 40px;
                    //padding: 3%;
                    //margin-left: 6%;
                    margin-bottom: 1rem;
                    margin-top: 1rem;
                    text-align: center;
                    //font-family: Verdana, Geneva, Tahoma, sans-serif;
                    background-color: #f1b56e;
                    border-radius: 25px;
                    border:1px solid #f1b56e;
                    color: white;
                    font-weight: 800;
                    text-transform: none !important;
                    cursor:pointer;
                    transform: background-color 1s, color 0.5s;
                }
                & .btn-quizz-arrow{
                    font-size : 1.2em;
                    color:white;
                    margin-left:0.2rem;
                }
                & .button-quizz:hover{
                    background-color:white !important;
                    color: #f1b56e;
                    & .btn-quizz-arrow{
                        animation: slide1 1s ease-in-out infinite;
                        color: #bd582d;
                    }
                }
            }
            & .quizz-feedback{
                font-size: 0.9rem;
                //border-radius: 8px;
                text-align: left;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                display: flex;
                //font-weight: bold;
                //color: white;
                justify-content: center;
                padding: 5px;
                align-items: center;
                animation: fade1 1s linear;
            }
            & .quizz-good{
                border:2px solid darkseagreen;
                background-color: rgba(darkseagreen,0.8);
                color:white;
            }
            & .quizz-bad{
                border:2px solid $orange;
                background-color: rgba($orange,0.8);
                color:white;
            }
        }
        & .end-msg{
            color: #3f3f3e;
            //font-family: Verdana, Arial, Tahoma, sans-serif;
            font-size: 1.4rem;
            width: 80%;
            margin-top: 10vh;
           // margin-bottom: auto;
            text-align: center;
            height: auto;
        }
        & .end-bloc-btn{
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            //padding-left: 0.6rem;
           // margin-left: 20px;
            width: 90%;
            //border:1px solid white;
            & .button-intro{
                background-color: #f1b56e !important;
                text-transform: none !important;
                color: white;
                font-weight: 800;
                border-radius:25px;
                width: 80%;
                height: 40px;
                //margin-top: 1rem;
                text-align: center;
                
                color: white;
                font-weight: 700;
                text-transform: none !important;
                transform: background-color 1s, color 0.5s;
                cursor: pointer;
            }
            & .button-intro:hover{
                color:#f1b56e ;
                background-color: white!important;
                border:1px solid #f1b56e;
            }
        }
    }
}
.video-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    & .choices-videos{
       position: absolute;
        bottom: 0;
        width: 80vw;
        height: 100px;
        max-width: 1000px;
        margin: auto;
        background-color: #EC6E51;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        & .button-choice{
            width:200px;
            border:2px solid white;
            border-radius: 0px;
            height: 60px;
            font-weight: 700;
            background-color: #EC6E51;
            color:white;
            transform: color, background-color;
        }
        & .button-choice:hover{
            background-color: #34398D !important;
            color:white;
            border:none;
        }
    }
    
}
.video-wrapper-2{
    //width: 80vw !important;
    max-width: 1000px;
    //border:1px solid red;
    margin: auto;
    //position: relative;
   // padding-top: 40%;
    border-radius: 0px !important;
    //overflow: scroll;
    //padding-top: 0;
    & .video-player{
     width: 100%;
     height: auto;
    } 

}
.video-bad{
    width: 90%;
    height: 80%;
    margin-top:auto;
    margin-bottom: auto;
    & >h3{
        font-size: 1.8rem;
        font-weight: 800;
        margin: 0px;
        //color: white;
    }
}
.video-wrapper-3{
    width: 100% !important;
    //max-width: 1000px;
    //border:1px solid red;
    margin: auto;
    //position: relative;
   // padding-top: 40%;
    border-radius: 0px !important;
    //overflow: scroll;
    //padding-top: 0;
    & .situation{
        font-size: 1.2rem;
        font-weight: 400;
        font-style: italic;
        margin-top: 10px;

    }
    & .video-player{
     width: 100%;
     height: auto;
    } 
    & .video-bloc-btn{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        //padding-left: 0.6rem;
       // margin-left: 20px;
        width: 90%;
        //border:1px solid white;
        & .button-intro{
            background-color: #f1b56e !important;
            text-transform: none !important;
            color: white;
            font-weight: 800;
            border-radius:25px;
            width: 80%;
            height: 40px;
            //margin-top: 1rem;
            text-align: center;
            
            color: white;
            font-weight: 700;
            text-transform: none !important;
            transform: background-color 1s, color 0.5s;
            cursor: pointer;
        }
        & .button-intro:hover{
            color:#f1b56e ;
            background-color: white!important;
            border:1px solid #f1b56e;
        }
    }

}

.video-bloc-btn{
    margin-top: 0.1rem;
    display: flex;
    justify-content: center;
    & .disabled{
        display: none;
    }
    & .button-video{
        width: 180px;
        height: 30px;
        //padding: 3%;
        //margin-left: 6%;
        margin-bottom: 1rem;
        margin-top: 1rem;
        text-align: center;
        background-color: white;
        border-radius: 20px;
        border:1px solid #ec6e51;
        color: #34398D;
        font-weight: 700;
        text-transform: none !important;
        transform: background-color 1s, color 0.5s;
        cursor: pointer;
       & .btn-video-arrow{
            font-size : 1.2em;
            color: #273a50;
            margin-left:0.2rem;
       }
    }
    & .button-video:hover{
        background-color:#ec6e51 !important;
        color: white;
        & .btn-video-arrow{
            color:white;
            animation: slide1 1s ease-in-out infinite;
        }
    }
    
}

@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
}

@media (min-width: 1700px) {
    .video-wrapper{
        padding-top: 56.25%;
        border-radius: 20px !important;
    }
}
@media (max-height:800px){
    .act-container{
        height: 62vh !important;
        & .quizz-viewer{
            // border:1px solid white;
             width: 90%;
             height: 100%;
             font-family: Verdana, Arial, Tahoma, sans-serif;
             justify-content: flex-start;

             & .question-bloc{
                
                 font-size: 1rem !important;
             }
             & .bloc-items{
                 & .quizz-item{
                    
                     font-size: 0.8rem!important;
                    
                 }
             }
             & .steps-bloc-btn{
                
                 & .button-quizz{
                     
                 }
                 & .btn-quizz-arrow{
                    
                 }
                
             }
             & .quizz-feedback{
                font-size: 0.8rem!important;
             }
             & .quizz-good{
                
             }
             & .quizz-bad{
                
             }
         }
         & .end-msg{
          
             font-size: 1rem;
             width: 80%;
             text-align: center;
             margin-top: auto;
             margin-bottom: auto;
             & >p{
                 font-size: 1rem!important;
             }
         }
         & .end-bloc-btn{
          
             & .button-intro{
                
             }
            
         }
        & .conclusion-img{
            height: 58vh;
        }
        & .content-conclusion-incipit{
            overflow-y: auto;
            height: 90%;
        }
        & .video-content-container{
            >h3{
                font-size: 1.4rem;
            }
        }
    }
    .video-bad{
        width: 90%;
        height: 90%;
        & >H3{
            font-size: 1.2rem;
            color: white;
        }
    }

}
@media only screen and (max-width:1375px) and (max-height:780px){
    .video-wrapper-3{
        width: 100% !important;
        //max-width: 1000px;
        //border:1px solid red;
        margin: auto;
        //position: relative;
       // padding-top: 40%;
        border-radius: 0px !important;
        //overflow: scroll;
        //padding-top: 0;
       // display: flex;;
        & .video-player{
         width: 90% !important;
         height: auto;
         margin-left: auto;
         margin-right: auto;
        } 
        & .video-bloc-btn{
            margin-top: 5px;
            width: 90%;
            //border:1px solid white;
            & .button-intro{
                background-color: #cb531b !important;
                text-transform: none !important;
                color: white;
                font-weight: 700;
                border-radius:20px;
                width: 80%;
                height: 40px;
                //margin-top: 1rem;
                text-align: center;
                
                color: white;
                font-weight: 700;
                text-transform: none !important;
                transform: background-color 1s, color 0.5s;
                cursor: pointer;
            }
            & .button-intro:hover{
                color:#ec6e51 ;
                background-color: white!important;
                border:1px solid #ec6e51;
            }
        }
    
    }

}