@import '../_master.scss';
.viewer{
    /*  display: flex;
     position: relative;
     flex-direction: column;
     align-items: center;
     justify-content: flex-end;
     text-align: left !important;
     color: $darkgray !important; */
     width:100vw!important;
     height: 100vh;
     //border:1px solid green;
     overflow-y: auto;
     overflow-x: hidden;
     position: relative;
     margin: 0;
    
}
.s-viewer{
    //border:1px solid green;
    justify-content: center;
    height: 100vh;
}
.m-background-0{
    background-color:#fcfcff;
    background-image: url("../images/forme-2.svg");
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center bottom;
}
.m-background-1{
    background-color:#fcfcff;
    background-image: url("../images/forme-3.svg");
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center bottom;
}
.m-background-2{
    background-color:#fcfcff;
    background-image: url("../images/forme-4.svg");
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center bottom;
}
.titre-bg-0{
    background-color: #a097e4;
}
.s-titre-bg-0{
    background-color: #efedff;
}
.titre-bg-1{
    background-color: #f1b56e;
}
.s-titre-bg-1{
    background-color: #fff8f0;
}
.titre-bg-2{
    background-color: #85d1a3;
}
.s-titre-bg-2{
    background-color: #e9faf5;
}

.activity-viewer{
   // max-width: 100vw !important;
    margin:0px !important;
    flex-wrap: wrap;
   // border:1px solid black;
    justify-content: center;
    align-items:flex-start !important;
    height: auto;
  // align-items: center;
    .steps-bloc-titre{
        display: flex;
        //position: relative;
       // border: 1px solid blue;
        height: 80px;
        //flex-direction: column;
        margin-top: 140px;
        margin-bottom: 0px;
        justify-content: flex-start;
        align-items: center;
        text-align: left !important;
        & .steps-titre{
                margin-left: 0vw;
                //background-color: #6cb52d;
                //border:1px solid red;
                height: 100%;
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                & h1{
                    color:white;
                    font-size: 1.8rem;
                    font-weight: 800;
                    text-transform: uppercase;
                     & b{

                     }
                }
        }
        & .steps-sous-titre{
            //background-color: #006b54;
            margin-right: 0;
            width: 70%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            & p{
                width:  90%;
                color: #3f3f3e;
                font-size: 1.2rem;
                
            }
        }
           
    }
    .divider-activité{
        //border: 1px solid red;;
        & h1{
            font-family: 'Karla', sans-serif;
            //border: 1px solid red;;
            width: auto;
            font-size: 32px;
            text-align: left;
            margin-left: 10vw;
            color:#29ABE2;
        }
    }
    .activity-container{
        //border:1px solid red;
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

    }
    .activité-bloc-card{
        //border-radius: 5px;
        border:none;
        filter:drop-shadow(0px 0px 20px rgba(0, 0, 0,0.15));
        display: flex;
        border-radius: 8px;
        //justify-content: flex-start;
        //align-items: center;
        height: 280px;
        width: 49%;
        margin-bottom: 2%;
        position: relative;
        & .checked-icon{
            position: absolute;
            width: 100px;
            height: 100px;
            //border:1px solid red;
            top:90px;
            left:10%;
            z-index: 10;
        }
        & .card-thumbnail{
            height: 280px;
            width: 40%;
            //border:1px solid red;
        }
        & .activité-bloc-text{
            padding-left: 0.5rem;
            width: 60%;
            height: 270px;
            color:#3f3f3e;
            text-align: left;
            //font-family: Verdana, Geneva, Tahoma, sans-serif;
           // border:1px solid red;
            position: relative;
            //align-items: center;
            //justify-content: center;
           
            & h4{
              margin-top:10px;
              text-align: left;
              width:90%;  
              margin-bottom: 5px;
              margin-left: 10%;
              text-transform: uppercase;
            //border:1px solid red;
              padding: 0px !important;
              font-weight: 800;
              font-size: 1.4rem;
            }
            & >div{

                width: 90%;
                height: auto;
                max-height: 200px;
                overflow: hidden;
                text-align: left;
                margin-top: 10px;
                font-size: 1.1rem;
                margin-left: 10%;
                & >p{
                    //border:1px solid red;
                    margin-top: 0px;
                    margin-bottom: 5px;
                }
            }
            & p{
                width: 100%;
                height: auto;
                max-height: 100px;
                //border:1px solid red;
            }
            // & h4{
            //     //margin: 0;
            // }
        }
        & .button-activity{
            width: 120px;
            height: 38px;
            //padding: 3%;
            position: absolute;
            bottom: 48px;
            //margin-left:20%;
            //margin-top: 20px;
            left:35px;
            text-align: center;
           // background-color: #cb531b;
            border-radius: 25px;
            //border:1px solid #ec6e51;
            color: white;
            font-weight: 700;
            text-transform: none !important;
            transform: background-color 1s, color 0.5s;
            cursor: pointer;
            & .btn-arrow{
                font-size : 2em;
                color: #273a50;
                margin-left:0 .2rem;
            }
            
        }
        .btn-bg-0{
            background-color: #a097e4 !important    ;
        }
        .btn-bg-0:hover{
            border:1px solid #a097e4 !important;
            color: #a097e4;
            background-color: white !important;
        }
        .btn-bg-1{
            background-color: #f1b56e !important    ;
        }
        .btn-bg-1:hover{
            border:1px solid #f1b56e !important;
            color: #f1b56e;
            background-color: white !important;
        }
        .btn-bg-2{
            background-color: #85d1a3 !important    ;
        }
        .btn-bg-2:hover{
            border:1px solid #85d1a3 !important;
            color: #85d1a3;
            background-color: white !important;
        }
    }
}
.top-page-activity{
    //height: 130px;;
    //flex-direction: column;
    margin-top: 140px!important;
    width: 80%!important;
    height: 80vh;
    // margin-left: 10%!important;
    text-align: left !important;
    //border: 1px solid red;
    padding: 0px !important;
    .arrow-container{
        width: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        position: relative;
        .arrow-back{
            position: absolute;
            left: -40px;
            cursor: pointer;
            color: #00b5d3;
            opacity: 0.4;
            transform:scale(1);
            transition: opacity,transform 0.6s;
            
        }
        .arrow-back:hover{
            transform:scale(2);
            opacity: 1;
        }
        .arrow-fwd{
            position: absolute;
            right: -40px;
            cursor: pointer;
            color: #00b5d3;
            opacity: 0.4;
            transition: opacity,transform 0.6s;
        }
        .arrow-fwd:hover{
            transform:scale(2);
            opacity: 1;
        }
    }
    & .masthead{
        display: flex;
        align-items: center;
        justify-content: start;
        & .mast-stepper{
            //border:1px solid green;
            width:30% !important;
            margin: 0px!important;
        }
    }
    & .steps-bloc-titre{
        display: flex;
        //position: relative;
        //border: 1px solid blue;
        height: 100px;;
        //flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: left !important;
        padding: 0px;
        & .steps-titre{
                //margin-left: 10vw;
               // background-color: #6cb52d;
                //border:1px solid red;
                height: 100%;
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                & h1{
                    color:white;
                    font-size: 24px;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-family: Verdana, Geneva, Tahoma, sans-serif;
                     & b{

                     }
                }
        }
        & .steps-sous-titre{
            //background-color: #006b54;
            //margin-right: 10vw;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 62%;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            display: flex;
            justify-content: space-between;
            & p{
                margin-left: 20px;
                //width:  90%;
                //color: white;
                font-size: 1.2rem;
                //border:1px solid white;
                
            }
            & .close-btn{
                margin-right: 20px !important;
                cursor: pointer;
                //border:1px solid white;
            }
        }
           
    }
    & .steps-back-btn{
        background-color: #cb531b !important;
        text-transform: none !important;
        color: white;
        font-weight: 700;
        border-radius:20px;
        
        width: 120px;
        height: 30px;
        //padding: 3%;
        //margin-left:20%;
        //margin-top: 20px;
        text-align: center;
        //border:1px solid #ec6e51;
        transform: background-color 1s, color 0.5s;

        & .steps-back-arrow{
            font-size : 1.2em;
            color: white;
            margin-right: 0.3rem;
        }
    }
    & .steps-back-btn:hover{
        //background-color: white !important;
        border:1px solid #ec6e51;
        color: #ec6e51;
        & .steps-back-arrow{
           // color:#ec6e51;
            animation: slide2 1s ease-in-out infinite;
        }
    }
    .MuiStepper-root{
        width: 70%;
        margin: auto;
    }
    .save-btn{
        margin-left: auto;
        margin-top : -1.5rem;
        background-color: #00b5d3;
        display:flex;
        flex-direction: column;
        justify-content: center;
        width:67px;
        height:65px;
        position: absolute;
        //z-index:300;
        //bottom: -150px;
        //top:295px;
        top:30vh;
        left:87.5%;
        .save-icon{
            color: white !important;
            font-size: 32px;
            //justify-content: flex-end;
        }
        & p{
            font-size: 0.7rem;
        }
    }
}


.green{
    color:green;
    margin-left:10px;
}
.red{
    color:$red;
    margin-left:10px;
}
.white{
    color:white;
    margin-left:10px;
}
.info-bulle-2{
    position: absolute;
    left: 81.5%;
    filter:drop-shadow(0px 0px 4px rgba(0, 0, 0,0.1));
    width: 123px;
    top:30vh;
    z-index: 148;
    height: 60px;
    background-color: #3f3f3e;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    color: white;
   
}
@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
}

@keyframes slide2 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(-10px, 0);
    }
}

@media (min-width: 1700px) {
    .activity-viewer{
        //max-width: 1700px !important;    
    }
    .top-page-activity{
        //max-width: 1700px !important;
    }
}
@media (min-width: 1800px) {
    .activity-viewer{
        //max-width: 1800px !important;    
    }
    .top-page-activity{
        //max-width: 1800px !important;
    }
}
@media (min-width: 1900px) {
    .activity-viewer{
        //max-width: 1900px !important;
    }
    .top-page-activity{
        //max-width: 1900px !important;
    }
}
@media (max-width:1100px){
    .activity-container{
        //border: 1px solid red;;
        .activité-bloc-card{
            width: 92%;
            margin-left: 4%;
            height: 220px;
        }
    }
    .button-activity{
        bottom:110px !important ;
        left: 55px!important;
    }

}
@media (max-height:800px){
    .activity-viewer{
        //border: 1px solid red !important;
        width: 98vw!important;
        height: 100vh!important;
        overflow-y: auto !important;
        .steps-bloc-titre{
            margin-top: 110px !important;
            height: 80px;
            & .steps-sous-titre{
                & p{
                    font-size: 1rem;
                }
            }
        }
    }
    .activity-container{
        margin-top: 10px !important;
        width: 78% !important;
        height: auto;
        .activité-bloc-card{
            & .activité-bloc-text{
                & h4{
                   // border:1px solid red;
                    font-size: 1.1rem;
                }
            }

        }
        //overflow-y: scroll !important;
    }
    .top-page-activity{
        margin-top: 110px !important;
        .steps-bloc-titre{
            margin-top: 10px !important;
            height: 80px;
            & .steps-titre{
                width: 40%;
                 & h1{
                     font-size: 1.4rem;
                 }
            }
            & .steps-sous-titre{
                & p{
                    font-size: 1rem;
                }
            }
        }
    }
}
/* @media (max-width: 1100px) {
    .activity-viewer{
        .activité-bloc-card{
            & .img-activité{
                padding: 1rem;
                width: 0px;
                height: 0px;
            }
            & .activité-bloc-text{
                padding-left: 0;
            }
            & .button-socle{
                padding-left: 1.7rem;
            }
        }
    }
}
 */
