.responsive-image{
    width: 100%;
    max-width: 900px;
    height: auto;
   //border:1px solid green;
}
.menu-v{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    width: 600px;
    & .bg-v{
        width: 600px;
        height: 452px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
       // border:1px solid;
       & .v-checked{
            width:150px;
            height: auto;
            position: absolute;
            left: 200px;
            top :100px;
       }
        & .label-v{
            position: absolute;
            bottom:0;
            left:0;
            background-color:rgba(43, 44, 43, 0.8);
            width: 400px;
            height: 166px;
            color:white;
            display: flex;
            flex-direction: column;
            align-items: center;
           justify-content: center;
           text-align: justify;
            & >h3{
                font-family: 'Permanent Marker', cursive;
                font-size: 1.2rem;
                margin:5px;
                text-align: left;
                width: 80%;
            }
            & >p{
                font-family: 'Montserrat', sans-serif;
                font-size: 0.9rem;
                margin:5px;
                width: 80%;
            }
        }
    }
    & .v-gray{
        filter: grayscale(80%);
    }
}
.slider {
    appearance: none;
    width: 60vw;
    height: 15px;
    background: rgba(255,255,255,0.7);
    outline: none;
    border-radius: 8px;
    filter: drop-shadow(0 0 0.10rem rgba(0,0,0,0.4));
  }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25%;
    height: 15px;
    background: white;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25%;
    height: 15px;
    background: white;
    border-radius: 8px;
    cursor: pointer;
  }
  .over-notification{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,0.6);
        z-index: 300;
        top:0px;
        width: 100vw;
        height: 100vh;
    & .notification{
        width:50vw;
        height:auto;
        min-height: 120px;
        padding: 15px;
        color:#333;
        //font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        //position: fixed;
        z-index: 347;
        //bottom:30px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: #00b5d3;
        color: white;
        & .header{
            display: flex;
            width: 100%;
            //border:1px solid blue;
            align-items: center;
            justify-content: flex-end;
            & h3{
                font-family: Verdana, Arial, Helvetica, sans-serif, Tahoma, sans-serif;
                font-size: 2rem;
                align-self: flex-start;
                margin-left: 20px;
            }
            & .btn-close{
                display: flex;
               align-items: center;
                //border:1px solid blue;
                font-family:Verdana, Arial, Helvetica, sans-serif, Tahoma, sans-serif;
                font-size: 1rem;
                font-weight: 700;
                margin-right: 20px;
                cursor: pointer;
            }
        }
        & >p{
            width:80%;
            //border:1px solid white;
        }
    }
  }

 
.item-document{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
    height: 50px;
    font-family: 'Montserrat', sans-serif;
    font-size:0.7rem;
    border-radius: 10px;
    //background-color:rgba(197,157,110,0.7);
    background-color:  rgba(102, 160, 164, 0.8);
    color:white;
    //border:1px solid #222;
    filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.2));
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    transform: scale(1);
    transition: transform,0.8;
    cursor: pointer;
    & p{
        width: 80%;
        text-align: left;
        margin-left: 10px;
        font-weight: 700;
        //border: 1px solid black;
    }
    & .icon-doc{
        margin-left: 15px;
        margin-right: 5px;
    }
   
}
.item-document:hover{
    transform: scale(1.1);
}
.item-document-big{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 280px;
    height: 100px;
    font-family: 'Montserrat', sans-serif;
    font-size:1rem;
    border-radius: 10px;
    //background-color:rgba(197,157,110,0.7);
    background-color:  rgba(102, 160, 164, 0.8);
    color:white;
    //border:1px solid #222;
    filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.4));
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    transform: scale(1);
    transition: transform,0.8;
    cursor: pointer;
    & p{
        width: 80%;
        text-align: left;
        margin-left: 10px;
        font-weight: 700;
        //border: 1px solid black;
    }
    & .icon-doc{
        margin-left: 25px;
        margin-right: 5px;
    }
   
}
.item-document-big:hover{
    transform: scale(1.05);
}