@import '../_master.scss';

.titre-conclusion{
    font-style: bold;
    font-family: $font-title;
    font-size: 24px;
    //font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: left;
    display: flex;
    align-items: center; 
    margin-left: 30px;
    //animation: fade1 1s linear;
    & > h3{
        letter-spacing: -1.5px;
        font-size: 1.8rem;
        font-size: 800;
        color: #3f3f3e;
    }     
    & > p{
        font-size: 1rem;
        color: $midgray;
        letter-spacing: 0.5px;
    } 
}
.content-conclusion{
    width: 100%;
    margin-top: 2% !important;
    height: 65vh !important;
    background-color: white !important;
    border-radius:8px;
    filter:drop-shadow(0px 0px 20px rgba(0, 0, 0,0.15));
    display: flex;

    & .conclusion-img{
        height: 65vh;
        width: 40%;
        background-position: center;
        background-size: cover;
    }
    & .content-conclusion-incipit{
        text-align: left;
        padding-top: 0 !important;
        width:60%;
        font-size: 1rem;
        //font-family: Verdana, Geneva, Tahoma, sans-serif;
        display: flex;
        flex-direction: column;
        color: #3f3f3e;;
        overflow-y: auto;
        //animation: fade1 1s linear 1s;
        
        & .text-conclusion{
            margin-left: 30px;
            max-height: 60vh;
            //overflow-y: auto;
            & >ul {
                margin-left: 20px;
                //border:1px solid red;
                //max-height: 400px;
                //overflow: hidden;
            }
            & ul > li {
                margin-bottom: 10px;
            }
        }

    }
    & .conclusion-bloc-btn{
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        padding-left: 0.6rem;
        margin-left: 20px;
        animation: fade1 1s linear 1.5s;
        & .button-conclusion{
            width: 200px;
            height: 38px;
            //padding: 3%;
            //margin-left: 6%;
            margin-bottom: 1rem;
            margin-top: 1rem;
            text-align: center;
           
           //font-family: Verdana, Geneva, Tahoma, sans-serif;
            border-radius: 25px;
            color: white;
            font-weight: 800;
            text-transform: none !important;
            transform: background-color 1s, color 0.5s;
            cursor: pointer;
            & .arrow-btn-conclusion{
                font-size : 1.2em;
                color: white;
                margin-left:0.2rem;
            }
        }
        .btn-bg-0{
            background-color: #a097e4 !important    ;
        }
        & .btn-bg-0:hover{
            border:1px solid #a097e4 !important;
            color: #a097e4 !important;
            background-color: white !important;
            & .arrow-btn-intro{
                color: #a097e4 !important;
                animation: slide1 1s ease-in-out infinite;
            }
        }
        & .btn-bg-1{
            background-color: #f1b56e !important    ;
        }
        & .btn-bg-1:hover{
            border:1px solid #f1b56e !important;
            color: #a097e4;
            background-color: white !important;
            & .arrow-btn-intro{
                color: #a097e4 !important;
                animation: slide1 1s ease-in-out infinite;
            }
        }
        & .btn-bg-2{
            background-color: #85d1a3 !important    ;
        }
        & .btn-bg-2:hover{
            border:1px solid #85d1a3 !important;
            color: #a097e4;
            background-color: white !important;
            & .arrow-btn-intro{
                color: #a097e4 !important;
                animation: slide1 1s ease-in-out infinite;
            }
        }
    }
}
@keyframes fade1 {
    0%   {opacity: 0}
    100% {opacity: 1;}
  }

@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
  }
 @media (max-height:800px){
    .content-conclusion{
        height: 65vh !important;
        overflow-y: auto;
        & .conclusion-img{
            height: 65vh;
        }
        & .content-conclusion-incipit{
            font-size: 0.9rem;
            //height: 90%;
            & .text-conclusion{
                margin-left: 30px;
                //max-height: 0vh;
                //overflow-y: auto;
                & >ul {
                    margin-left: 20px;
                    //border:1px solid red;
                    //max-height: 400px;
                    //overflow: hidden;
                    margin-top: -10px;
                }
                & ul > li {
                    margin-bottom: 5px;
                    font-size: 0.8rem;
                }
            }
        }
    }
    .titre-conclusion{
        & > h3{
            font-size: 1.6rem !important;
        }

       
    }

}
@media only screen and (max-width:1375px) and (max-height:780px){
    .content-conclusion{
        height: 62vh !important;
        overflow-y: auto;
        & .conclusion-img{
            height: 62vh;
        }
    }

}
// @media only screen and (max-width: 1500px) and (min-device-width: 480px) {
//     // .titre-conclusion{
//     //     height: 5rem;
//     //     line-height: 80px;
//     // }
//     // .content-conclusion{
//     //     height: 70vh !important;
//     // }
// }