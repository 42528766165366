
html {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  //font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
