@import '../_master.scss';
.nav{
    width: 100vw;
    height: 100px;
    position: fixed;
    top:0;
    left: 0;
    background-color:white;
    //border:1px solid red;
    display: flex;
    //justify-content: space-evenly !important;
    align-items: center !important;
    justify-content: center;
    flex-grow: 1;
    color: #34398D;
    z-index: 15;
    filter: drop-shadow(0px 2px 10px rgba(43, 43, 43, 0.08));
    & .n-wrapper{
        // border:1px solid red;
         display: flex;
         align-items: center;
         justify-content: space-between;
         //border:1px solid red;
         & .module-titre{
            width: 100%;
            height:100px;   
            
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        & .bloc-marque{
            margin-left:0;
            width: 140px;
            height: 50px;
            background-image: url('../images/logo.png');
            background-size: contain;
            background-position: center;
            margin-top: 0px;
            background-repeat: no-repeat;
          }
        & .titre-content{
            border-left: 1px solid #e9edf1;
            height: 100px;
            margin-left: 40px;
            & >h1{
                text-align: left;
                margin-top:-10px;
                padding: 0px;
                margin-left: 50px;
                margin-bottom: 0px;
                font-weight: 700;
                font-size: 30pt;
                color: #34398d;
              }
          & p{
              margin-top:-5px;
              color: $midgray;
              margin-left: 50px;
              font-size: 14px;
              text-align: left;
              color: #34398d;
          }
        }  
        & .titre-nav{
            font-family: $font-title;
            float: left;
            text-align: left;
            margin-left: 10%;
            width: 70%;
        }
        & .menu-icons{
            justify-self:flex-end;
            text-align: right;
            margin-right:0px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            //border:1px solid red;
            & .save-icon{
                margin:5px;
                .MuiIconButton-label.MuiSvgIcon-root{
                    color: #00b5d3 !important;
                }
                color: #00b5d3 !important;
            }
            & .home-icon{
                color: #00b5d3 !important;
            }
        }
    }
}
.tooltip{
    position:absolute;
    width: 123px;
    border-radius:5px;
    height: 46px ;
    background-color: #3f3f3e;
    color:white;
    font-size: 0.9rem;
    //font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 0.9rem;
    text-align:center;
    z-index: 25;
    bottom:-45px;
    left:-40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tooltip-2{
    position:absolute;
    width: 123px;
    border-radius:5px;
    height: 46px ;
    background-color: #3f3f3e;
    color:white;
    font-size: 0.9rem;
    //font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 0.9rem;
    text-align:center;
    z-index: 25;
    bottom:-45px;
    left:15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* @media only screen and (max-width: 1500px) and (min-device-width: 480px) {
    .nav{
        .MuiTypography-body2{
            font-size: 0.7rem;
        }
    }
    
} */

@media only screen and (max-width:1150px)  {
    .nav{
        height: 100px;
    }
    .module-titre{
        height: 100px !important;
       
       
    }
    .bloc-marque{
        margin-left:7vw !important;
        width: 200px;
        height: 100px !important;
       // border: 1px solid red;
        background-image: url('../images/logo.png');
        background-size: contain;
        background-position: 0px 0px;
        margin-top: 0px;
        background-repeat: no-repeat;
        //border:1px solid red;
      }
     
   
}
@media only screen and  (max-height:800px) {
    .nav{
        height: 100px;
    }
    .module-titre{
        height: 100px !important;
        background-position: 35vw 0px !important;
        background-size: 115px 115px !important ;
       
       
    }
    .bloc-marque{
        margin-left:7vw !important;
        width: 280px;
        height: 100px !important;
       // border: 1px solid red;
        background-image: url('../images/logo.png');
        background-size: contain;
        background-position: 0px 0px;
        margin-top: 0px;
        background-repeat: no-repeat;
        //border:1px solid red;
      }
}
