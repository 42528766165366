@import '../_master.scss';

.titre-images{
    font-style: bold;
    font-family: $font-title;
    margin-top: -2rem !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > h3{
        letter-spacing: -1.5px;
        font-size: 48px;
    }     
    & > p{
        font-size: 16px;
        color: $midgray;
        letter-spacing: 0.5px;
    }
}
.content-steps{
    overflow: scroll !important;
    width: 100%;
    height: 60vh !important;
    background-color: white !important;
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
    flex-wrap: wrap;
    & .arrow-stepper{
        border: 1px solid $blue;;
    }
    & .content-viewer{
        text-align: left;
        width: 80%;
        padding: 0;
        margin: 20px;
        font-size: 16px;
        & .step-container{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            & > img  {
                width: 75%;
                flex :1;
            }
            & > p{
                background-color: $blue;
                color: white;
                font-size: 16px;
                width: 100%;
                margin-bottom: 0;
            }
        }
      
    }
}
.steps-bloc-btn{
    align-self: start;
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    & .button-images{
       height: 45px;
       font-size: 16px;
       width: 200px;
       background-color: transparent !important;
       text-transform: none !important;
       & .btn-images-arrow{
        font-size : "2em";
        color: "#273a50";
        margin-left: "0.2rem";
        }
    }
    & .button-images:hover{
        & .btn-images-arrow{
            animation: slide1 1s ease-in-out infinite;
        }
    }
}

@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
}

@media only screen and (max-width: 1500px) and (min-device-width: 480px) {
    .titre-images{
        height: 7rem;
        line-height: 30px;
        & > h3{
            margin-top: 0;
            padding-top: 1rem;
            margin-bottom: 0;
        }     
        & > p{
            margin-top: 0;
        }
        

    }
}

