@import '../_master.scss';

.save-viewer{
    width: 95% !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    //border-top: 0.5px solid lightgray;
    margin-top: 110px !important;
    height: calc(100vh - 110px);
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    .save-bloc-titre{
        display: flex;
        position: relative;
        flex-direction: column;
        margin-top: 1rem;
        justify-content: flex-start;
        text-align: left !important;
            & h1{
                color: #336756;
                font-size: 2rem;
                margin-bottom: 1rem;
                font-family: Verdana, Arial, Helvetica, Tahoma, sans-serif
                //letter-spacing: -1.5px;
            }
    }
    .content-saver{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        //border: 1px solid red;
        width:33%;
        min-width: 300px;
    }
    .save-card{
            border-radius: 5px;
           
           
            max-width: 480px;
            height: 290px;
            filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.1));
            border: none;
            margin: 10px;
            position: relative;
        .save-close-btn{
            //float: right;
            position:absolute;
            top:20px;
            right: 10px;
            color: white;
        }
        .img-save{
            width: 100%;
            height: 150px;
            background-position: center;
        }
        .save-card-content-wrapper{
            font-family: Verdana, Arial, Helvetica, Tahoma, sans-serif;
            //font-weight: 700;
            color: #336756;
        }
        .card-titre-save{
            font-weight: 700;
            //border: 1px solid red;
        }

    }
    .baseline{
        width: 100vw;
        height: 2.5rem;
        position: fixed;
        bottom:0;
        left: 0;
        position: relative;
    }
}

@media (min-width: 1700px) {
    .activity-viewer{
        max-width: 100% !important;
    }
}