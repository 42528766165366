@import '../_master.scss';

.titre-cases{
    padding-bottom: 0;
    text-align: left !important;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > h3{
        margin: 0;
        font-style: bold;
        letter-spacing: -1.5px;
        font-size: 35px;
    }
    & p{
        margin-top: 40px;
        color: #34398D;
        //letter-spacing: 0.5px;
        padding-bottom: 0 !important;
        font-size: 16px;
        margin: 9px;
        //border:1px solid red;
    }   
}
.content-cases{
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
    flex-wrap: wrap;
    //border:1px solid red;
    height: 80%;
    & .cases-comment{
        background-color:#EC6E51;
        border-radius: 0;
        color:white;
        padding: 15px;
        font-size: 20px;
        margin-top: 20px;
        font-weight: bold;
        width: 90%;
        filter:none !important;
        margin-bottom: 0.5rem;
         & ul > li {
            margin-bottom: 30px;
         }
    }
    & .cases-viewer {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;   
        align-items: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 55vh !important;
        filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.2)) !important;
        background-color: white;
        
        & .cases-item{
            width: 95%;
            height: 3.8rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            font-weight:bold;
            color:#34398D;
            border: 2px solid #34398D;
            border-radius: 0px;
            text-align: left;
            margin-bottom : 0.4rem;
            margin-top : 0.4rem;
            & p{
                //margin:0 !important;
                //border: 1px solid red;;
                margin-right: 20px;
            }
            
            &.borderGood{
                border: 5px solid green;
                margin-left: 4%;
            }
           &.borderWrong{
                border: 5px solid red;
                margin-left: 4%;
            }
        }
        & .cases-item:hover{
                color:white;
                background-color: #34398D;
                cursor: pointer;
            & > * {
                color: white;
                cursor: pointer;
            }
        }
        & .cases-answer{
            width: 95%;
            height: 5rem;
            border:1px solid #c9d0d9;
            display: flex;
            background-color: white;
            align-items: center;
            padding-left:0;
            justify-content: flex-start;
            font-size: 16px;
            border-radius: 0px;
            margin-top: 2rem;
            margin-left: 1rem;
            text-align: left;
            z-index: 6;
            &.borderGood{
                border: 2px solid green;
            }
            &.borderWrong{
                border: 2px solid red;
            }
            
        }
        & .cases-feedback{
            z-index: 0;
            width: 100%;
            margin-top: 2rem;
            margin-left: 4%;
            padding: 1%;
            font-size: 16px;
            //font-weight: bold;
            text-align: left;
            display: flex;
            color: black;
            justify-content: center;
            //align-items: center;
            
        }
        .long-feedback{
            width: 15rem;
            margin-left: 0;
        }
        .large-feedback{
            height: 300px;
            text-align: left;
            width: 300px;
            left: 68% !important;
            position: absolute;
            background-color: white;
            padding: 2%
            //overflow: scroll;
        }
        .short-answer-feedback{
            margin-left: 0;
            width: 15rem;
            height: 4rem;
            & p{
                padding-left: 10px;
            }
        }
    }
    .cases-bloc-btn{
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        padding-left: 0.6rem;
        margin-left: 20px;
        & .Mui-disabled{
            opacity: 0.4;
            cursor: default;
        }
        & .button-cases{
            width: 180px;
            height: 30px;
            //padding: 3%;
            //margin-left: 6%;
            margin-bottom: 1rem;
            margin-top: 1rem;
            text-align: center;
            background-color: white;
            border-radius: 20px;
            border:1px solid #ec6e51;
            color: #34398D;
            font-weight: 700;
            text-transform: none !important;
            transform: background-color 1s, color 0.5s;
            cursor: pointer;
           & .btn-cases-arrow{
            font-size : 1.2em;
            color: "#273a50";
            margin-left: 0.2rem;
            }
        }
        & .button-cases:hover{
                background-color:#ec6e51 !important;
                color: white;
            & .btn-cases-arrow{
                animation: slide1 1s ease-in-out infinite;
            }
        }
    }
    
    .feedback-large-btn{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        & .button-steps{
            border-radius: 20px;
            height: 45px;
            font-size: 16px;
            width: 200px;
            text-transform: none !important;
         }
    }
}
.cases-img-bloc{
    border-radius: 0 !important;
    display: flex;
    justify-content: center;
    margin-top:0px;
    height: 55vh !important;
    filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,0.2)) !important;
    border:none;
    background-color: white;
   
    .cases-img{
        width: 100%;
        height: 55Vh !important;
        //border: 1px solid $blue;
        border-radius: 0;
        background-position: center;
        //filter:drop-shadow(3px 1px 8px rgba(0, 0, 0,1)) !important;
        background-size: contain;
    } 
}

@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
}


@media only screen and (min-width: 1700px) {
    .content-steps{
        & .cases-viewer {
            & .cases-item{
                height: 3rem;
                font-size: 14px;
                padding: 0.3rem;
            }
        }
    }
}

