$red:#e9041e;
$orange: #ee3b45;
$dark:#010035;
$color-choice: rgb(122,199,194);
$purple: rgb(70, 48, 112);
$turquoise: #7bc7c1;
$midgray: #999999;
$lightgray: #e6e6e6;
$darkgray: #273a50;
$blue:#34398d;
$logo:'./images/logo.svg';
$max-width-med: 620px;
$header-height: 100px;
$sidebar-width: 350px;

$progress-height: 6px;
$progress-height-md: 4px;

$sidebar-width-md: 250px;
$sidebar-width-sm: 200px;
$header-height-sm: 75px;
$header-height-tiny: 50px;

$tab-width: 290px;
$tab-width-md: 220px;
$tab-width-sm: 70px;

$device-large: 1680px;
$device-medium: 1440px;
$device-small: 1024px;
$device-mobile: 768px;

$bounceEase: cubic-bezier(0.64, 0.57, 0.67, 1.53);

$font-title:'Roboto', sans-serif;
$font-content:'Source Sans Pro';
