@import "_master.scss";
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.App {
  text-align: center;
  //font-family: 'Karla', sans-serif;
  font-size: 16pt;
  
}
div{
  scrollbar-color: #00b5d3 lightgray;
  scrollbar-width: thin;
}
div::-webkit-scrollbar {
  width: 0.7rem;
  background-color: lightgray;
}
div::-webkit-scrollbar-thumb {
    background: #00b5d3; 
}
*:not(html) {
  -webkit-transform: translate3d(0, 0, 0);
}
