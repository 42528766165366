@import '../_master.scss';


.content-wrapper{
    width: 80vw;
    margin-top: 2% !important;
    //margin-left: 1%;
    height: 65vh !important;
    background-color: white !important;
    border-radius:8px !important;
    filter:drop-shadow(0px 0px 20px rgba(0, 0, 0,0.15));
    display: flex;
   
    & .intro-img{
        //border: 1px solid;
        height: 65vh;
        width: 30%;
        background-position: center;
        background-size: cover;
    }
    & .content-quizz{
        position: relative;
        display: flex;
        flex-direction: column;
        //justify-content: center;
        align-items: center;
        width:70%;
        border:none !important;
        //flex-wrap: wrap;
        & .titre-quizz{
            text-align: left;
            padding-top: 0 !important;
            margin-top: 20px;
            width:90%;
            font-size: 1.6rem;
            display: flex;
           // font-family: Verdana, Geneva, Tahoma, sans-serif;
            flex-direction: column;
            //border:1px solid red;
            color: #3f3f3e;
            & > h3{
                font-size: 1.8rem;
                font-weight: 800;
                //border:1px solid red;
                margin-top:0px;
                margin-bottom: 0px;
            }     
            & > p{
                font-size: 1rem;
                color: #3f3f3e;
                //border:1px solid red;
                margin-bottom: 0px;
                margin-top: 5px;;
            } 
        }
        & .quizz-viewer {
            display: flex;
            width: 90%;
            padding: 0;
            margin-top:5px;
            flex-direction: column;
            //border:1px solid red;
           
            justify-content: space-evenly;   
            //align-items: center;
            //font-family: Verdana, Geneva, Tahoma, sans-serif;
            & .question-bloc{
                width:96.5%;
                height: auto;
                padding: 10px;
                line-height: 25px;
                font-size: 1.2rem;
                
                color: white;
                font-weight: 800;
                vertical-align: middle;
                
            }
            & .bg-0{
                background-color: #a097e4;
            }
            & .bg-1{
                background-color: #f1b56e;
            }
            & .bg-2{
                background-color: #85d1a3;
            }
            & .bloc-items{
                max-height: 300px;
                overflow-y: auto;
                overflow-x: hidden;
                width: 100%;
                //border:1px solid red;

            }
            & .quizz-item{
                width:99%;
                min-height: 60px;
                height:auto;
                margin-bottom: 0.6rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 1rem;
                border-radius: 0px;
                border:2px solid #3f3f3e;
                color: #3f3f3e;
                font-weight: normal;
                cursor: pointer;
                text-align: left;
            }
            & .quizz-item:hover{
                background-color: #3f3f3e;
                border:2px solid #3f3f3e;
                & > * {
                    color: white;
                }
            }
           
            
        }
  
    }
    & .steps-bloc-btn{
        display: flex;
        //justify-content: flex-end;
        align-items: center;
      //  border:1px solid red;
        width:99%;
        & .button-quizz{
            width: 200px;
            height: 40px;
            //padding: 3%;
            //margin-left: 6%;
            margin-bottom: 1rem;
            margin-top: 1rem;
            text-align: center;
            border-radius: 25px;
            color: white;
            font-weight: 700;
            text-transform: none !important;
            cursor:pointer;
            transform: background-color 1s, color 0.5s;
        }
        & .btn-quizz-arrow{
            font-size : 1.2em;
            color:white;
            margin-left:0.2rem;
        }
        & .btn-bg-0{
            background-color:#a097e4 !important;
        }
        & .btn-bg-0:hover{
            background-color:white !important;
            color: #a097e4;
            border: 1px solid #a097e4;
            & .btn-quizz-arrow{
                animation: slide1 1s ease-in-out infinite;
                color: #a097e4;
            }
        }
        & .btn-bg-1{
            background-color:#f1b56e !important;
        }
        & .btn-bg-1:hover{
            background-color:white !important;
            color: #f1b56e;
            border: 1px solid #f1b56e;
            & .btn-quizz-arrow{
                animation: slide1 1s ease-in-out infinite;
                color: #f1b56e;
            }
        }
        & .btn-bg-2{
            background-color:#85d1a3 !important;
        }
        & .btn-bg-2:hover{
            background-color:white !important;
            color: #85d1a3;
            border: 1px solid #85d1a3;
            & .btn-quizz-arrow{
                animation: slide1 1s ease-in-out infinite;
                color: #85d1a3;
            }
        }
    }
    & .quizz-feedback{
        width: 28.5%;
        height: 62vh;
        padding: 0.7rem;
        position: absolute;
        font-size: 1rem;
        //border-radius: 8px;
        text-align: left;
        //font-family: Verdana, Geneva, Tahoma, sans-serif;
        display: flex;
        font-weight: bold;
        //color: white;
        justify-content: center;
        align-items: center;
        animation: fade1 1s linear;
    }
    & .quizz-good{
        border:2px solid darkseagreen;
        background-color: rgba(#336756,0.9);
        color:white;
    }
    & .quizz-bad{
        border:2px solid $orange;
        background-color: rgba($orange,0.9);
        color:white;
    }
    & .feedback-viewer{
        //border:1px solid red;
        overflow-y: auto;
        margin-top: 0px;
        height: 100%;
        & .p-header{
            font-family: 800;
            display: flex;
            font-size: 1.6rem;
            justify-content: center;
            align-items: center;
            height: 70px;
            background-color: #f1b56e;
            color:white;
            & >p{
                width: 90%;
            }
        }
        & .p-description{
            background-color: #fff8f0;
            padding:10px;
            font-size: 0.9rem;
            margin-top:5px;
        }
    }
}



@keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
}
@keyframes fade1 {
    0%   {opacity: 0}
    100% {opacity: 1;}
  }
  @media (max-height:800px){
    .content-wrapper{
        //height: 62vh!important;
        & .content-quizz{
            & .titre-quizz{
               margin-top: 10px;
                & > h3{
                    font-size: 1.6rem !important;
                }
                & > p{
                    font-size: 0.9rem;
                    margin-bottom: 0px;
                } 
            }        
        }
        & .quizz-viewer {
            margin-bottom: 0px;
            //border: 1px solid blue;
            & .question-bloc{
                font-size: 1rem;
                line-height: 1rem;
                padding: 5px;
                //margin-right: 15px !important;
                width: 97% !important;
               
            }
            & .bloc-items{
               // border:1px solid red;
                max-height: 100% !important;
                margin-bottom: 0px !important;
            }
            & .quizz-item{
                height: 50px !important;
                font-size: 0.9rem !important;
            }
            
        }
        & .quizz-feedback{
         font-size: 0.8rem;    
         height: 61vh;   
        }
        & .steps-bloc-btn{
            margin-top: 0;
            & .button-quizz{
                margin-bottom: 1rem;
                margin-top: 0;
                
            }
            & .btn-quizz-arrow{
                font-size : 1.2em;
                color:white;
                margin-left:0.2rem;
            }
            & .button-quizz:hover{
                background-color:white !important;
                color: #bd582d;
                & .btn-quizz-arrow{
                    animation: slide1 1s ease-in-out infinite;
                    color: #bd582d;
                }
            }
        }

    }
   
  }
  @media only screen and (max-width:1375px) and (max-height:780px){
    .content-wrapper{
        height: 62vh !important;
        //overflow-y: auto!important;
        //border:1px solid red;

        & .intro-img{
            //border: 1px solid;
            height: 62vh;
        }
        & .content-quizz{
            //border:1px solid red !important;
            overflow-y: auto!important;
        }
       & .quizz-viewer{
           //border:1px solid red;
       }
       & .quizz-feedback{
        font-size: 0.8rem;    
        height: 58vh;   
       }
    }
     .bloc-items{
        max-height: auto !important;
        overflow-y: hidden !important;
        overflow-x: hidden;
        width: 100%;
        //border:1px solid red;

    }
   
  }